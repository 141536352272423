@import '../../../assets/global-style/mixin';

.partners {
    &__list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;

        @include before_768 {
            grid-template-columns: 1fr;
        }
    }

    &__card {
        display: flex;
        justify-content: center;
        padding: 45px;
        background-color: var(--color-white);
        border-radius: 24px;
        transition: 0.3s;

        &:hover {
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
        }
    }
}
