@import '../../../assets/global-style/mixin';

.slide {
    margin-bottom: 104px;

    &__content {
        display: flex;
        gap: 30px;

        @include before_1024 {
            flex-direction: column;
            align-items: center;
        }
    }

    &__left {
        width: 50%;
        padding: 50px;
        background-color: var(--color-blue);
        border-radius: 24px;

        @include before_1024 {
            width: 100%;
        }

        @include before_768 {
            padding: 24px 16px;
        }
    }

    &__right {
        position: relative;
        overflow: hidden;
        width: 637px;
        height: 604px;
        border-radius: 24px;

        @include before_1200 {
            width: 500px;
            height: 470px;
        }

        @include before_480 {
            width: 100%;
        }
    }

    &__title {
        margin-bottom: 44px;
        font-size: 36px;
        font-weight: 500;
        color: var(--color-white);

        @include before_768 {
            font-size: 18px;
        }
    }

    &__text {
        margin-bottom: 16px;
        font-size: 24px;
        color: var(--color-white);

        @include before_768 {
            font-size: 16px;
        }
    }

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
    }

    &__button {
        border-color: var(--color-white);
    }
}
