@import '../../../assets/global-style/mixin';

.banner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    padding: 40px;
    margin-bottom: 104px;
    border-radius: 36px;
    background-repeat: no-repeat;
    background-size: cover;

    @include before_1024 {
        grid-template-columns: 1fr;
    }

    @include before_768 {
        padding: 24px 16px;
    }

    &__right {
        display: flex;
        align-items: flex-end;
    }

    &__breadcrumbs {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
        margin-bottom: 57px;
    }

    &__breadcrumbs-item {
        display: flex;
        align-items: center;
        gap: 16px;
        color: var(--color-white);
    }

    &__title {
        margin-bottom: 56px;
        font-size: 40px;
        font-weight: 500;
        color: var(--color-white);
        text-transform: uppercase;

        @include before_768 {
            font-size: 18px;
        }
    }

    &__tag-list {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        max-width: 480px;
    }

    &__tag-item {
        padding: 8px 16px;
        font-size: 18px;
        color: var(--color-white);
        text-transform: uppercase;
        border: 2px solid var(--color-white);
        border-radius: 36px;

        @include before_768 {
            font-size: 14px;
        }
    }

    &__img {
        margin-bottom: -40px;

        @include before_768 {
            margin-bottom: -24px;
        }
    }
}
