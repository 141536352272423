.start {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.start__img-box {
  background: linear-gradient(-10deg, #003140 50%, #ffffff 50%);
}
.start__img {
  margin: 0 auto;
}
.start__block {
  flex-grow: 1;
  width: 100%;
  background-color: #003140;
}

.icon {
  fill: var(--icon-primery-color);
}
.icon--black {
  fill: var(--color-black);
}

.banner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  padding: 40px;
  margin-bottom: 104px;
  border-radius: 36px;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 1023px) {
  .banner {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 767px) {
  .banner {
    padding: 24px 16px;
  }
}
.banner__right {
  display: flex;
  align-items: flex-end;
}
.banner__breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 57px;
}
.banner__breadcrumbs-item {
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--color-white);
}
.banner__title {
  margin-bottom: 56px;
  font-size: 40px;
  font-weight: 500;
  color: var(--color-white);
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .banner__title {
    font-size: 18px;
  }
}
.banner__tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-width: 480px;
}
.banner__tag-item {
  padding: 8px 16px;
  font-size: 18px;
  color: var(--color-white);
  text-transform: uppercase;
  border: 2px solid var(--color-white);
  border-radius: 36px;
}
@media screen and (max-width: 767px) {
  .banner__tag-item {
    font-size: 14px;
  }
}
.banner__img {
  margin-bottom: -40px;
}
@media screen and (max-width: 767px) {
  .banner__img {
    margin-bottom: -24px;
  }
}

.lesson {
  margin-bottom: 104px;
}
.lesson__subtitle {
  margin-bottom: 40px;
}
.lesson__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}
.lesson__card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 440px;
  padding: 40px;
  background-color: var(--color-white);
  border-radius: 24px;
  background-repeat: no-repeat;
  background-position: right bottom;
}
@media screen and (max-width: 479px) {
  .lesson__card {
    background-size: contain;
  }
}
.lesson__card--first {
  grid-column: 1/4;
}
.lesson__title {
  max-width: 550px;
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: 500;
}
.lesson__text {
  max-width: 360px;
  color: var(--color-gray);
}

.about {
  margin-bottom: 104px;
}

.partners__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
@media screen and (max-width: 767px) {
  .partners__list {
    grid-template-columns: 1fr;
  }
}
.partners__card {
  display: flex;
  justify-content: center;
  padding: 45px;
  background-color: var(--color-white);
  border-radius: 24px;
  transition: 0.3s;
}
.partners__card:hover {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.slide {
  margin-bottom: 104px;
}
.slide__content {
  display: flex;
  gap: 30px;
}
@media screen and (max-width: 1023px) {
  .slide__content {
    flex-direction: column;
    align-items: center;
  }
}
.slide__left {
  width: 50%;
  padding: 50px;
  background-color: var(--color-blue);
  border-radius: 24px;
}
@media screen and (max-width: 1023px) {
  .slide__left {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .slide__left {
    padding: 24px 16px;
  }
}
.slide__right {
  position: relative;
  overflow: hidden;
  width: 637px;
  height: 604px;
  border-radius: 24px;
}
@media screen and (max-width: 1199px) {
  .slide__right {
    width: 500px;
    height: 470px;
  }
}
@media screen and (max-width: 479px) {
  .slide__right {
    width: 100%;
  }
}
.slide__title {
  margin-bottom: 44px;
  font-size: 36px;
  font-weight: 500;
  color: var(--color-white);
}
@media screen and (max-width: 767px) {
  .slide__title {
    font-size: 18px;
  }
}
.slide__text {
  margin-bottom: 16px;
  font-size: 24px;
  color: var(--color-white);
}
@media screen and (max-width: 767px) {
  .slide__text {
    font-size: 16px;
  }
}
.slide__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
.slide__buttons {
  display: flex;
  justify-content: flex-end;
}
.slide__button {
  border-color: var(--color-white);
}