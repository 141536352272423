@import '../../../assets/global-style/mixin';

.lesson {
    margin-bottom: 104px;

    &__subtitle {
        margin-bottom: 40px;
    }

    &__list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        min-height: 440px;
        padding: 40px;
        background-color: var(--color-white);
        border-radius: 24px;
        background-repeat: no-repeat;
        background-position: right bottom;

        @include before_480 {
            background-size: contain;
        }

        &--first {
            grid-column: 1 / 4;
        }
    }

    &__title {
        max-width: 550px;
        margin-bottom: 20px;
        font-size: 36px;
        font-weight: 500;
    }

    &__text {
        max-width: 360px;
        color: var(--color-gray);
    }
}
