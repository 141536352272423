@import '../../../assets/global-style/mixin';

.sbertabs {
    display: flex;
    flex-direction: column;

    &__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        @include before_1024 {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__list {
        display: flex;
        align-self: flex-start;
        gap: 16px;
        margin-bottom: 24px;
    }

    &__name {
        border-radius: 24px;
        background-color: var(--color-white);
        padding: 14px 16px;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        color: var(--color-black);
        cursor: pointer;
        transition: 0.5s;

        &.active {
            color: var(--color-white);
            background-color: #26c93c;
            box-shadow: 0px 8px 16px 0px rgba(36, 201, 33, 0.2);
        }
    }

    &__right {
        @include before_1024 {
            margin-top: 12px;
        }
    }
}
